<script setup>
import { computed } from 'vue';
import { sentenceCase } from '@emobg/web-utils';
import {
  INVESTIGATION_RESOLUTION_STATUS_COLORS,
  INVESTIGATION_STATUS_COLORS,
} from '../const/investigations.const';

const props = defineProps({
  status: {
    type: [String, null],
    default: null,
  },
  isResolution: {
    type: Boolean,
    default: false,
  },
});

const COLORS = computed(() => (props.isResolution
  ? INVESTIGATION_RESOLUTION_STATUS_COLORS
  : INVESTIGATION_STATUS_COLORS));
</script>

<template>
  <ui-badge
    v-if="status"
    :color="COLORS[status] || GRAYSCALE.ground"
    :text="sentenceCase(status)"
    class="InvestigationStatusBadge"
  />
  <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
</template>
