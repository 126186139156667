import moment from 'moment-timezone';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { DATE_FORMAT, objectValues } from '@emobg/web-utils';
import { DocumentThumbnail } from '@/components';

export const DAMAGE_BLUEPRINT_SIDE_NAMES = {
  front: {
    position: 0,
    label: 'Front',
  },
  left: {
    position: 1,
    label: 'Driver side',
  },
  back: {
    position: 2,
    label: 'Rear',
  },
  right: {
    position: 3,
    label: 'Passenger side',
  },
};

export const DAMAGES_STATUS = {
  pendingReview: 'pending_review',
  notRepaired: 'not_repaired',
  repaired: 'repaired',
  duplicated: 'duplicated',
  rejected: 'rejected',
};

export const DAMAGES_STATUS_COLORS = {
  [DAMAGES_STATUS.pendingReview]: COLORS.moderate,
  [DAMAGES_STATUS.notRepaired]: COLORS.danger,
  [DAMAGES_STATUS.repaired]: COLORS.success,
  [DAMAGES_STATUS.duplicated]: GRAYSCALE.ground,
  [DAMAGES_STATUS.rejected]: GRAYSCALE.ground,
};

export const EXTERIOR_DAMAGE_TYPES = ['exterior_body_damage'];

export const DAMAGES_TYPES = [
  'exterior_body_damage',
  'interior_damage',
  'broke_windscreen',
  'missing_parts',
  'equipment_defect',
  'door_problem',
  'tires',
  'battery_defect',
  'mechanical_breakdown',
  'other',
];

export const DAMAGE_ATTACHMENT_SCHEMA = openPreviewModal => [
  {
    header: 'Upload date',
    template: attachment => attachment.uploadDate || moment().format(DATE_FORMAT.dob),
  },
  {
    header: 'Attachment',
    component: DocumentThumbnail,
    props: (document, index) => ({
      image: document.imageUrl,
      name: document.imageName,
      onClickImage: () => {
        openPreviewModal(document, index);
      },
    }),
  },
];

const SYSTEM_USER_UUID = 'c7a89c01-56fe-45f8-9e59-7c6f69edcb63';

/**
 * Checks if the provided uuid matches with the system user for automatic created damages
 * @param uuid
 * @returns {boolean}
 */
export const isSystemUser = uuid => SYSTEM_USER_UUID === uuid;

export const DAMAGE_REJECTED_REASONS = {
  blurryPicture: 'blurry_picture',
  darkPicture: 'dark_picture',
  dirtyVehicle: 'dirty_vehicle',
  peoplePersonalDetails: 'people_personal_details',
  shinyPicture: 'shiny_picture',
  unclearLocation: 'unclear_location',
  wrongLocation: 'wrong_vehicle',
};

export const DAMAGE_REJECTED_REASONS_VALUES = objectValues(DAMAGE_REJECTED_REASONS);

export const DAMAGE_REJECTED_REASONS_MAPPER = {
  [DAMAGE_REJECTED_REASONS.peoplePersonalDetails]: 'People / Personal details',
};
